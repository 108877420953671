<template>
    <div>
        <title-bar :title="$t('navigations.tighteningTorques')" />
        <columns-toggle
            :columns-array="defaultColumns"
            :action="showColumnsModal"
            :page="page"
            @clearAction="showColumnsModal = false"
            @init="beforeSetColumns"
            @columnsChange="setColumns"
        />
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="tableActions"
                :addBtnText="$t('messages.addNew')"
                :addBtnLink="$helper.getAddUrl('tightening-torques')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @columnsChange="showColumnsModal = true"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:navisionItem="data">
                  <div v-if="data.value.navisionItem">
                    {{ $helper.getEnumTranslation("ITEM_CATEGORY", data.value.navisionItem.itemCategory, this.$i18n.locale) }} -
                    {{ data.value.navisionItem.name }}
                    {{ data.value.navisionItem.number }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:insertedBy="data">
                  <div v-if="data.value.insertedBy">
                    {{ data.value.insertedBy.givenName + " " + data.value.insertedBy.familyName }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('tightening-torques', data.value.id)" />
                        <delete-btn v-if="canDelete" @pressDelete="deleteItem(data.value['@id'])" />
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import TableLiteUtils from "@/mixins/TableLiteUtils";
import ColumnsToggle from "@/components/Table/ColumnsToggle.vue";

export default {
    name: "TighteningTorques",
    components: {
      ColumnsToggle,
        TableLite,
        TablePagination,
        DeleteBtn,
        TableActions,
        EditBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils, ListUtils, TableLiteUtils],
    created() {
        this.setPermissions()
        this.perPage = this.getTableLiteSettingsForPageAndKey(this.page, 'perPage')
        this.load()
    },
    methods: {
        setColumns(data) {
          this.columns = data
          this.setTableLiteSettings(this.page, {columns: this.columns})
        },
        beforeSetColumns() {
          const columns = this.getTableLiteSettingsForPageAndKey(this.page, 'columns')
          if (columns && columns.length > 0) {
            this.defaultColumns.forEach(element => {
              if (columns.includes(element.field)) {
                element.enabled = true
              } else {
                element.enabled = false
              }
            })
          }
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                itemCategory: 'TIGHTENING_TORQUES'
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            })
            this.$Items.getCollection({params}, 'tightening_torque_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
      setPermissions() {
        if (this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('CREATE', 'TIGHTENING_TORQUES')) {
          this.tableActions.push('addBtn')
        }
        this.canUpdate = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('UPDATE', 'TIGHTENING_TORQUES')
        this.canDelete = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('DELETE', 'TIGHTENING_TORQUES')
      },
      deleteItem(url) {
          this.deleteByUrl(this.$Skills, url, this.$t('item.deleted'), null, this.load, this.error);
      },
      onChangePage(page) {
        this.currentPage = page;
        this.load();
      },
      perPageChange(num) {
        this.currentPage = 1;
        this.perPage = num;
        this.setTableLiteSettings(this.page, {perPage: this.perPage})
        this.load();
      },
      inputChange(value) {
        this.currentPage = 1;
        this.search = value;
        this.load();
      },
      setSort(offset, limit, order, sort) {
        this.sortable.order = order;
        this.sortable.sort = sort;

        if (order === 'navisionItem') {
          this.sortable.order = order + '.number';
        }

        this.load();
      },
    },
    data() {
        return {
            search: "",
            page: 'tighteningTorques',
            isLoading: false,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "id",
                sort: "asc",
            },
          canUpdate: false,
          canDelete: false,
          tableActions: ['search', 'perPage','advancedFilter', 'columnsBtn'],
            filterFields: [
                {
                    label: "forms.navisionItem",
                    name: "navisionItem",
                    value: null,
                    type: "customSelect",
                    resource: this.$Items,
                    resourceType: "item",
                    entityType: "NAVISION_ITEM"
                },
                {
                    label: 'forms.manufacturer',
                    name: "manufacturer",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.minTorque',
                    name: "minTorque",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.maxTorque',
                    name: "maxTorque",
                    value: null,
                    type: "text",
                },
                {
                    label: "forms.insertedBy",
                    name: "insertedBy",
                    value: null,
                    type: "customSelect",
                    resource: this.$Users,
                    resourceType: "employee",
                },
            ],
            advancedParams: [],
            showColumnsModal: false,
            columns: [],
            defaultColumns: [
                {
                    label: this.$t("forms.minTorque"),
                    field: "minTorque",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.maxTorque"),
                    field: "maxTorque",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.navisionItem"),
                    field: "navisionItem",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.manufacturer"),
                    field: "manufacturer",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.hyperlink"),
                    field: "hyperlink",
                    sortable: false,
                    enabled: false,
                },
                {
                    label: this.$t("forms.insertedBy"),
                    field: "insertedBy",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                    enabled: true,
                },
            ],
            rows: null
        };
    },
};
</script>

